// React
import React, { useContext } from 'react'
// Global - Context
import { AppContext } from 'context'

const NavLeft: React.FC = () => {
  const { loggedInUser } = useContext(AppContext)
  return (
    <>
      <div id='left-nav-profile-block'>
        <div
          className='p-3 bg-white border'
          id='left-nav-profile-block-img-wrapper'
        >
          <img alt='' src={loggedInUser.supplier.logoURL} />
        </div>
        <div id='left-nav-profile-block-account'>
          <h5>{loggedInUser.supplier.name}</h5>
        </div>
        <div id='left-nav-profile-block-username'>
          Logged In As:
          <br />
          {loggedInUser.username}
        </div>
      </div>
    </>
  )
}

export default NavLeft
