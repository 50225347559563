// React
import React from 'react'
// Global - API's
import { APIAuthCurrentUserAPIResponse } from 'api/auth/current-user/response'

export const AppContext = React.createContext({} as AppContextDetails)

export interface AppContextDetails {
  loggedInUser: APIAuthCurrentUserAPIResponse
  setLoggedInUser: React.Dispatch<
    React.SetStateAction<APIAuthCurrentUserAPIResponse | undefined>
  >
  userToken: string
  setUserToken: React.Dispatch<React.SetStateAction<string | undefined>>
}
