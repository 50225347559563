// React
import React, { useCallback, useContext, useEffect, useState } from 'react'
// Routing
import { Redirect, useParams, useHistory } from 'react-router-dom'
// MainStem - UI
import { Loader,toast } from '@mainstem/react-mainstem'
// Global - Config
import { HTTP } from 'config'
// Global - Context
import { AppContext } from 'context'
// Global - API's
import { apiAuthCurrentUser } from 'api/auth/current-user'

export const LayoutImpersonate = () => {
  const { token, redirect } = useParams<any>()
  const history = useHistory()
  const { setLoggedInUser, setUserToken } = useContext(AppContext)
  const [loading, setLoading] = useState<boolean>(true)

  const handleGetCurrentUser = useCallback(() => {
    apiAuthCurrentUser().then((apiResponseCurrentUser) => {
      if (apiResponseCurrentUser.supplier) {
        setLoggedInUser(apiResponseCurrentUser)
        const token = window.localStorage.getItem('mainstem_user_token')
        if (token) {
          setUserToken(token)
        }
        toast.success('Successfully logged in. ' + redirect)
        // Redirect back to main page.
        console.log(redirect)
        if (redirect) {
          if (redirect === 'onboard') {
            history.push('/account/onboard')
          } else {
            // Split it by _ and create the proper route.
            if (redirect.includes('_')) {
              const redirectAsRoute = redirect.split('_').join('/')
              history.push('/' + redirectAsRoute)
            } else {
              history.push('/' + redirect)
            }
          }
        } else {
          history.push('/')
        }
      } else {
        toast.error(
          'Failed to impersonate, Sorry - Try to refreshing impersonation token if possible.'
        )
      }
    })
  }, [history, redirect, setLoggedInUser, setUserToken])

  useEffect(() => {
    if (token) {
      window.localStorage.setItem('mainstem_user_token', token)
      HTTP.defaults.headers.Authorization = `Bearer ${token}`
      handleGetCurrentUser()
      setLoading(false)
    }
  }, [token, handleGetCurrentUser])

  return loading ? <Loader /> : <Redirect to='/' />
}
