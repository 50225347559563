// API - Response
import { APIAuthLogoutAPIResponse } from './response'

/** Will log a user out */
export async function APIAuthLogout() {
  // Prepare our API response
  const apiResponse = {
    wasSuccessful: false
  } as APIAuthLogoutAPIResponse

  // Remove our user token from local storage
  window.localStorage.removeItem('mainstem_user_token')

  // Set our API response to successful
  apiResponse.wasSuccessful = true

  // Return with our API response
  return apiResponse
}
