import { createGlobalStyle } from 'styled-components'
export const GlobalStyle = createGlobalStyle`
  #ms-alert-bart-link {
    color: #fff;
  }

  label {
    width: 100%;
  }
`
