import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

const NavTopSearch = () => {
  const history = useHistory()
  const [query, setQuery] = useState<string>('')
  const checkSubmit = (e: any) => {
    if (e.key === 'Enter') {
      handleSubmit(e)
    }
  }
  const handleSubmit = async (e: any) => {
    e.preventDefault()
    if (query === '') {
      return
    }
    history.push(`/search-results/${query}`)
    setQuery('')
  }
  return (
    <>
      <input
        id='ms-nav-top-container-right-search-input'
        onChange={({ target }) => setQuery(target.value)}
        onKeyUp={checkSubmit}
        placeholder='Search for products, addresses, etc ...'
        value={query}
      />
    </>
  )
}

export default NavTopSearch
