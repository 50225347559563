// React
import React, { lazy, Suspense } from 'react'
// Routing
import { Redirect, Route, Switch } from 'react-router-dom'
// MainStem
import { CenterScreen, Loader } from '@mainstem/react-mainstem'

/* =============================================================================
                                  Pages
============================================================================= */

const PageAccount = lazy(() => import('pages/account'))
/* Orders Pages */
const PageOrdersList = lazy(() => import('pages/orders/list'))
const PageOrdersDetails = lazy(() => import('pages/orders/details'))
/* Products Pages */
const PageProductsList = lazy(() => import('pages/products/list'))
const PageProductsCreate = lazy(() => import('pages/products/create'))
const PageProductsDetails = lazy(() => import('pages/products/details'))
/* Integration Pages */
const PageIntegrationsDashboard = lazy(() => import('pages/integrations/dashboard'))
const PageIntegrationImplementationEDI = lazy(() => import('pages/integrations/implementations/edi'))
const PageIntegrationImplementationEmail = lazy(() => import('pages/integrations/implementations/email'))
const PageIntegrationImplementationHawthorne = lazy(() => import('pages/integrations/implementations/hawthorne'))
const PageIntegrationImplementationHydrofarm = lazy(() => import('pages/integrations/implementations/hydrofarm'))
const PageIntegrationImplementationShopify = lazy(() => import('pages/integrations/implementations/shopify'))
const PageIntegrationImplementationWooCommerce = lazy(() => import('pages/integrations/implementations/woo-commerce'))
const PageIntegrationImplementationBigCommerce = lazy(() => import('pages/integrations/implementations/big-commerce'))
/* Other Pages */
const PageAdvertising = lazy(() => import('pages/advertising'))
/* Search Page */
const PageSearchResults = lazy(() => import('pages/search-results'))
/* Onboard Page */
const PageOnboard = lazy(() => import('pages/onboard'))
/* RFQ Pages */
const PageRFQDashboard = lazy(() => import('pages/rfq/dashboard'))
const PageRFQDetails = lazy(() => import('pages/rfq/details'))
const PageRFQOpportunities = lazy(() => import('pages/rfq/opportunities'))
const PageRFQBidCreate = lazy(() => import('pages/rfq/bid/create'))
/* RFQ Invoice Inquiry */
const PageInvoiceInquiryDetails = lazy(() => import('pages/invoice-inquiry/details'))
/* News Pages */
const PageNews = lazy(() => import('pages/news'))
const PageErr = lazy(() => import('pages/err'))
// Lazy Loaded Pages
const lazyLoadedPages = {
  account: lazy(() => import('pages/account')),
  dashboards: {
    marketInsights: lazy(() => import('pages/dashboards/market-insights')),
    performance: lazy(() => import('pages/dashboards/performance')),
    sales: lazy(() => import('pages/dashboards/sales')),
    scoreCard: lazy(() => import('pages/dashboards/score-card'))
  }
}

export const Routes = () => (
  <Suspense
    fallback={
      <CenterScreen>
        <Loader />
      </CenterScreen>
    }
  >
    <Switch>
      <Route exact path='/'>
        <Redirect to='/dashboard/sales' />
      </Route>
      <Route path={'/account'}>
        <PageAccount />
      </Route>
      <Route path={'/account/:onboard?'}>
        <PageAccount />
      </Route>
      <Route exact path='/orders/list'>
        <PageOrdersList />
      </Route>
      <Route exact path='/orders/details/:orderId/:invoiceId'>
        <PageOrdersDetails />
      </Route>
      {/* Product - Routes */}
      <Route exact path='/products/list'>
        <PageProductsList />
      </Route>
      <Route exact path='/products/create'>
        <PageProductsCreate />
      </Route>
      <Route exact path='/products/details/:id'>
        <PageProductsDetails />
      </Route>
      {/* Integrations - Routes */}
      <Route exact path='/integrations/dashboard'>
        <PageIntegrationsDashboard />
      </Route>
      <Route exact path='/integrations/edi'>
        <PageIntegrationImplementationEDI />
      </Route>
      <Route exact path='/integrations/email'>
        <PageIntegrationImplementationEmail />
      </Route>
      <Route exact path='/integrations/hawthorne'>
        <PageIntegrationImplementationHawthorne />
      </Route>
      <Route exact path='/integrations/hydrofarm'>
        <PageIntegrationImplementationHydrofarm />
      </Route>
      <Route exact path='/integrations/shopify'>
        <PageIntegrationImplementationShopify />
      </Route>
      <Route exact path='/integrations/woo-commerce'>
        <PageIntegrationImplementationWooCommerce />
      </Route>
      <Route exact path='/integrations/big-commerce'>
        <PageIntegrationImplementationBigCommerce />
      </Route>
      {/* Dashboard - Redirects */}
      <Route exact path='/market-insights'>
        <Redirect to='/dashboard/market-insights' />
      </Route>
      <Route exact path='/performance'>
        <Redirect to='/dashboard/performance' />
      </Route>
      <Route exact path='/score-card'>
        <Redirect to='/dashboard/score-card' />
      </Route>
      {/* Dashboard - Routes */}
      <Route exact path='/dashboard/market-insights'>
        <lazyLoadedPages.dashboards.marketInsights />
      </Route>
      <Route exact path='/dashboard/performance'>
        <lazyLoadedPages.dashboards.performance />
      </Route>
      <Route exact path='/dashboard/sales'>
        <lazyLoadedPages.dashboards.sales />
      </Route>
      <Route exact path='/dashboard/score-card'>
        <lazyLoadedPages.dashboards.scoreCard />
      </Route>
      {/* Dashboard - Advertising */}
      <Route exact path='/advertising'>
        <PageAdvertising />
      </Route>
      {/* Search - Routes */}
      <Route exact path='/search-results/:query?'>
        <PageSearchResults />
      </Route>
      {/* Onboard - Routes */}
      <Route exact path='/onboard'>
        <PageOnboard />
      </Route>
      {/* RFQ - Routes */}
      <Route exact path='/rfq/dashboard'>
        <PageRFQDashboard />
      </Route>
      <Route exact path='/rfq/details/:id'>
        <PageRFQDetails />
      </Route>
      <Route exact path='/rfq/opportunities'>
        <PageRFQOpportunities />
      </Route>
      <Route exact path='/rfq/bid/create/:id'>
        <PageRFQBidCreate />
      </Route>
      {/* Invoice Inquiry */}
      <Route exact path='/invoice-inquiry/details/:id'>
        <PageInvoiceInquiryDetails />
      </Route>
      <Route path={'/news'}>
        <PageNews />
      </Route>
      <Route>
        <PageErr />
      </Route>
    </Switch>
  </Suspense>
)
