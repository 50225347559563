// React
import React, { useCallback, useContext, useEffect, useState } from 'react'
// Routing
import { Redirect, useParams, useHistory } from 'react-router-dom'
// MainStem - UI
import { Loader, toast } from '@mainstem/react-mainstem'
// Global - Config
import { HTTP } from 'config'
// Global - Context
import { AppContext } from 'context'
// Global - API's
import { apiAuthCurrentUser } from 'api/auth/current-user'
import { apiAcceptInvite } from 'api/invitation-impersonation'
// Local - TypeScript Types
import { IInvitationImpersonationURLParams } from './types'

export const InvitationImpersonation = () => {
  const { supplierUUID, userUUID, redirect } =
    useParams<IInvitationImpersonationURLParams>()
  const history = useHistory()
  const { setLoggedInUser, setUserToken } = useContext(AppContext)
  const [loading, setLoading] = useState<boolean>(true)

  const handleGetCurrentUser = useCallback(() => {
    apiAuthCurrentUser().then((apiResponseCurrentUser) => {
      if (apiResponseCurrentUser.supplier) {
        setLoggedInUser(apiResponseCurrentUser)
        const token = window.localStorage.getItem('mainstem_user_token')
        if (token) {
          setUserToken(token)
        }
        toast.success('Successfully logged in.')
        // Redirect back to main page.
        if (redirect) {
          if (redirect === 'onboard') {
            history.push('/account/onboard')
          } else {
            // Split it by _ and create the proper route.
            if (redirect.includes('_')) {
              const redirectAsRoute = redirect.split('_').join('/')
              history.push('/' + redirectAsRoute)
            } else {
              history.push('/' + redirect)
            }
          }
        } else {
          history.push('/')
        }
      } else {
        toast.error(
          'Failed to impersonate, Sorry - check the token if you copy/pasted'
        )
      }
    })
  }, [history, redirect, setLoggedInUser, setUserToken])

  const handleInviteAccepted = useCallback(() => {
    apiAcceptInvite({
      supplierUUID,
      userUUID
    }).then((apiResponse: any) => {
      if (apiResponse.wasSuccessful) {
        const token = apiResponse.token
        window.localStorage.setItem('mainstem_user_token', token)
        HTTP.defaults.headers.Authorization = `Bearer ${token}`
        handleGetCurrentUser()
      } else {
        toast.error('Failed to accept invite - Use online chat for assistance.')
      }
    })
  }, [supplierUUID, userUUID, handleGetCurrentUser])

  useEffect(() => {
    if (supplierUUID && userUUID) {
      handleInviteAccepted()
      setLoading(false)
    }
  }, [supplierUUID, userUUID, handleInviteAccepted])

  return loading ? <Loader /> : <Redirect to='/' />
}
