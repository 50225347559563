// Routing
import { useLocation } from 'react-router-dom'
// React Error Boundary
import { ErrorBoundary } from 'react-error-boundary'
// Global - Pages
import PageErr from 'pages/err'
// Global - Routes
import { Routes } from 'routes'

/***
 * This error wrapper is used to wrap the entire application in an Error Boundary.
 * The key on the ErrorBoundary allows the error to be reset anytime the user navigates to a new page/route.
 */
const ErrorWrapper: React.FC = () => {
  const location = useLocation()
  return (
    <>
      <ErrorBoundary fallback={<PageErr />} key={location.pathname}>
        <Routes />
      </ErrorBoundary>
    </>
  )
}

export { ErrorWrapper }
