import { HTTP } from 'config'

export async function apiAcceptInvite(apiRequest:any) {
  let apiResponse = {
    wasSuccessful: false
  }

  await HTTP.post('/invitation-impersonation', apiRequest)
    .then((res) => {
      if (res.status === 200 && res.data) {
        apiResponse = res.data
        apiResponse.wasSuccessful = true
      }
    })
    .catch()

  return apiResponse
}
