import {
  faAd,
  faAddressCard,
  faBullseyeArrow,
  faChartArea,
  faChartPie,
  faChartNetwork,
  faTasksAlt,
  faCopy,
  faBarcodeRead,
  faFileCertificate,
  faNewspaper,
  faTachometerAltFast
} from '@fortawesome/pro-duotone-svg-icons'

export const links = [
  {
    icon: faTachometerAltFast,
    title: 'Dashboards',
    bases: ['/dashboard/'],
    links: [
      {
        title: 'Sales',
        to: '/dashboard/sales',
        icon: faChartPie,
        exact: '/',
        bases: ['/dashboard/sales']
      },
      {
        title: 'Performance',
        to: '/dashboard/performance',
        icon: faChartArea,
        exact: '/dashboard/performance'
      },
      {
        title: 'Market Insights',
        to: '/dashboard/market-insights',
        icon: faTasksAlt,
        exact: '/dashboard/market-insights'
      },
      {
        title: 'Score Card',
        to: '/dashboard/score-card',
        icon: faBullseyeArrow,
        exact: '/dashboard/score-card'
      }
    ]
  },
  {
    title: 'Account',
    to: '/account',
    icon: faAddressCard,
    exact: '/account'
  },
  {
    title: 'Products',
    to: '/products/list',
    icon: faBarcodeRead,
    bases: ['/products']
  },
  {
    title: 'Orders',
    to: '/orders/list',
    icon: faCopy,
    bases: ['/orders']
  },
  {
    title: 'Request for Quote',
    to: '/rfq/dashboard',
    icon: faFileCertificate,
    bases: ['/rfq']
  },
  {
    title: 'News',
    to: '/news',
    icon: faNewspaper,
    bases: ['/news']
  },
  {
    title: 'Advertising',
    to: '/advertising',
    icon: faAd,
    bases: ['/advertising']
  },
  {
    title: 'Integrations',
    to: '/integrations/dashboard',
    icon: faChartNetwork,
    bases: ['/integrations']
  }
]
