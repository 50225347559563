// React
import React from 'react'
// Routing
import { Link } from 'react-router-dom'

const NavTopAlertBar: React.FC = () => {
  return (
    <>
      <Link id='ms-alert-bart-link' to='/rfq/dashboard'>
        You can now bid on <strong>Requests For Quotes</strong> in your Supplier
        Core™ account!
      </Link>
    </>
  )
}

export default NavTopAlertBar
