// 3rd Party - LogRocket
import LogRocket from 'logrocket'
// Global - Config
import { HTTP } from 'config'
// API - Response
import { APIAuthCurrentUserAPIResponse } from './response'

export async function apiAuthCurrentUser() {
  let apiResponse = {} as APIAuthCurrentUserAPIResponse

  await HTTP.get('auth/currentUser').then((res) => {
    if (res.status === 200 && res.data) {
      apiResponse = res.data
      try {
        // LogRocket Identify
        LogRocket.identify(res.data.uuid, {
          name: res.data.friendlyName,
          email: res.data.username,
          supplierId: res.data.supplier.supplierId,
          supplierApiKey: res.data.supplier.apiKey,
          platform: 'Supplier Core'
        })
      } catch {
        console.error('Failed to initialize 3rd party integrations')
      }
    }
  })

  return apiResponse
}
