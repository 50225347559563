// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLifeRing } from '@fortawesome/pro-light-svg-icons'
// Local - Components
import { ButtonGoBack } from './components'

const NavTopButtons = () => {
  return (
    <>
      <ButtonGoBack />
      <a
        className='ms-nav-top-middle-menu-link'
        href='https://support.mainstem.io'
        rel='noopener noreferrer'
        target='_blank'
      >
        <div className='ms-nav-top-middle-menu-icon'>
          <FontAwesomeIcon icon={faLifeRing} />
        </div>
        <div className='ms-nav-top-middle-menu-text'>Support</div>
      </a>
      {/* 
        <div className='ms-nav-top-middle-menu-link' id='ms-nav-top-button-release'>
          <div className='ms-nav-top-middle-menu-icon'>
            <FontAwesomeIcon icon={faGem} />
          </div>
          <div className='ms-nav-top-middle-menu-text'>What's New?</div>
        </div>
      */}
      {/* 
      <div className='ms-nav-top-middle-menu-link active'>
        <div className='ms-nav-top-middle-menu-icon'>
          <FontAwesomeIcon icon={faStore} />
        </div>
        <div className='ms-nav-top-middle-menu-text'>Shop</div>
      </div>
      <div className='ms-nav-top-middle-menu-link'>
        <div className='ms-nav-top-middle-menu-icon'>
          <FontAwesomeIcon icon={faFileAlt} />
        </div>
        <div className='ms-nav-top-middle-menu-text'>Policies</div>
      </div>
      <div className='ms-nav-top-middle-menu-link'>
        <div className='ms-nav-top-middle-menu-icon'>
          <FontAwesomeIcon icon={faGavel} />
        </div>
        <div className='ms-nav-top-middle-menu-text'>Compliance</div>
      </div>
      <div className='ms-nav-top-middle-menu-link'>
        <div className='ms-nav-top-middle-menu-icon'>
          <FontAwesomeIcon icon={faGem} />
        </div>
        <div className='ms-nav-top-middle-menu-text'>What's New?</div>
      </div> */}
    </>
  )
}

export default NavTopButtons
